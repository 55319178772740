<template>
    <div>
        <b-row no-gutters class="min-vh-100">
            <b-col xs="12" sm="12" md="3" lg="2">
                <sider-menu-accordion_deprecated></sider-menu-accordion_deprecated>
            </b-col>
            <b-col xs="12" sm="12" md="9" lg="10">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import SiderMenuAccordion_deprecated from "@/components/general/elements/menus/SiderMenuAccordion_deprecated";
    export default {
        name: "CartContent_deprecated",
        components: {SiderMenuAccordion_deprecated},
        props: {},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {},
        computed: {},
        filters: {},
        watch: {}
    }
</script>

<style scoped>

</style>
